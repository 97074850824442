.status {
    color: #666;
    padding: 3px 12px;
    border-radius: 0.25rem;
    text-align: center;
    font-size: 13px;
    white-space: nowrap;
    border: 1px solid transparent;
    cursor: default;
    border-radius: 20px;
    position: relative;
}
span.status {
    display: inline-block;
}

.status-point {
    width: 14px;
    height: 14px;
    padding: 0;
    border-radius: 50%;
}

@mixin status-variant($background, $border) {
    color: color-yiq($background);
    @include gradient-bg($background);
    border-color: $border;
}

@mixin status-light-variant($background, $border, $color) {
    color: $color;
    @include gradient-bg($background);
    border-color: $border;
}

@mixin status-outline-variant(
    $color,
    $color-hover: color-yiq($color),
    $active-background: $color,
    $active-border: $color
) {
    color: $color;
    border-color: $color;

    @include hover {
        color: $color-hover;
        background-color: $active-background;
        border-color: $active-border;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow,
                    0 0 0 $btn-focus-width rgba($color, 0.5);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
            }
        }
    }
}

@mixin status-dot-variant($background, $border, $color) {
    color: $color;
    @include gradient-bg($background);
    border-color: $border;
}

@each $color, $value in $theme-colors {
    .status-#{$color} {
        @include status-variant($value, $value);
    }

    .status-light-#{$color} {
        @include status-light-variant(
            theme-color-level($color, $alert-bg-level),
            theme-color-level($color, $alert-border-level),
            theme-color-level($color, $alert-color-level)
        );
    }
    .status-outline-#{$color} {
        @include status-outline-variant(
            theme-color-level($color, $alert-bg-level),
            theme-color-level($color, $alert-border-level),
            theme-color-level($color, $alert-color-level)
        );
    }

    .status-dot-#{$color} {
        position: relative;
        padding-left: 14px;
        background-color: transparent;
        text-align: left;

        &::before {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            border-width: 2px;
            border-radius: 50%;
            left: 0;
            top: 50%;
            margin-top: -5px;
            background-color: $value;
        }
    }
}

.status-sm {
    border-radius: 2px;
    line-height: 1;
    padding: 2px 6px;
    font-size: 14px;
}
.status-type-1 {
    border-radius: 3px;
}

.status-circle{
    padding: 0;
    height: 30px;
    width: 30px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.status-light-blue {
    border-color: #e4efff;
    background-color: #e4efff;
    color: #3779f4;
}
