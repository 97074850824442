// $bg-primary:

$footer-padding: 60px;

body.no-scroll{
    overflow-y: hidden;
}

// $bg-primary: rgba(23,68,135);

.figure {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;
    display: block;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
    }
}

.header-primary {

    .header-nav-wrap{
        padding-top: 0;
        padding-bottom: 0;
    }

    .desktop-nav{
        // height: 100%;
        display: flex;
        align-items: center;
        // margin: 0;
        // margin-right: 8px;
        margin-bottom: 0;
    }
    .desktop-nav-divider{
        background: rgba(0, 0, 0, 0.15);
        margin: 0px 16px;
        width: 1px;
        height: 35px;
        display: block;
    }
    .desktop-nav-item{
        // margin-right: 8;

        &.dropdown{
            position: relative;
        }
    }
    .desktop-nav-link{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 48px;
        border-radius: 0.3rem;
        color: $body-color;

        &:hover{
            cursor: pointer;
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
    .desktop-nav-btn{
        min-width: 100px;
    }
    .desktop-nav-icon {
        color: inherit;
        display: inline-block;
        height: 24px;
        vertical-align: middle;
        width: 24px;

        svg{
            width: 24px;
            height: 24px;
        }
    }
    .desktop-nav-text{
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        white-space: nowrap;
        width: calc(68px);
        text-overflow: ellipsis;
        text-align: center;
        margin-top: 2px;
    }


    .header-topbar {
        background-color: $primary;
        color: color-yiq($primary);

        padding-top: 8px;
        padding-bottom: 8px;

        .header-topbar-link {
            color: color-yiq($primary);
        }
    }

    .header-nav {
        position: relative;
        z-index: 300;

        background-color: $white;
        color: color-yiq($white);
    }

    .navbar-brand {
        margin-top: 4px;
        margin-bottom: 4px;
        // padding-top: 4px;
        position: relative;
        // z-index: 1;
        // background-color: #fff;
        background-image: url(../images/logos/logo-2.svg);
        background-repeat: no-repeat;
        // background-size: cover;
        // height: 48px;
        // width: 148px;

        height: 100px;
        width: 100px;


        display: flex;
        align-items: center;

        >span{
            display: none;
        }

        img {
            height: 40px;
        }
    }

    .header-search {
        // width: 840px;
        position: relative;
        // margin-top: 32px;

        .searchbar {
            width: 100%;
            // box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.09);
            // padding: .1875rem;
            // background-color: rgba($primary, .2);
            // border-radius: 2px;
            // background-color: rgba($primary, .1);
        }

        .searchbar-input {
            display: flex;
            flex: 1;
            position: relative;

            .searchbar-icn{
                // margin-right: 12px;
                // flex-shrink: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;

                svg{
                    width: 24px;
                    height: 24px;
                }
            }

            .form-control {
                outline: none;
                // border: none;
                // padding: 0;
                // background-color: transparent;
                // border-color: rgba($white, .2);
                // background-color:  rgba($white, .98);

                border-color: rgba($light, 0.2);
                background-color: $light;
                padding-left: 45px;

                &:focus {
                    // box-shadow: none;
                    background-color: #fff;

                    box-shadow: 0 0 0 1px $primary;
                    border-color: $primary;
                }
            }
        }

        // .btn-search{
        //     color: color-yiq(#ce3249);
        //     background-color: #ce3249;
        //     border-color: #ce3249;

        //     &:hover,
        //     &.hover{
        //         color: color-yiq(#ce3249);
        //         background-color: darken(#ce3249, 7.5%);
        //         border-color: darken(#ce3249, 10%);
        //     }

        //     &:focus,
        //     &.focus {
        //         box-shadow: 0 0 0 $btn-focus-width rgba(#ce3249, .5);
        //     }

        //     &:active,
        //     &.active{
        //         color: color-yiq(#ce3249);
        //         background-color: darken(#ce3249, 10%);
        //         border-color: darken(#ce3249, 12.5%);
        //     }
        // }
    }
}
.desktop--menu-toggle {
    &:hover {
        .desktop--menu {
            opacity: 1;
            visibility: visible;
            transform: scaleY(1);
        }

        .desktop--menu-column {
            opacity: 1;
            transform: translateY(0);
            transition: transform 0.3s ease, opacity 0.3s linear;
            transition-delay: 0.2s;
        }
    }
}

.desktop--menu {
    position: absolute;
    padding-top: 30px;
    padding-bottom: 30px;
    left: 0;
    top: 100%;
    right: 0;
    background-color: color-yiq($primary);
    color: $primary;

    box-shadow: 0 5px 3px rgba(0, 0, 0, 0.1);

    transition: transform 0.1s ease, opacity 0ms linear 0.1s, visibility 0ms linear 0.1s;
    transform-origin: top center;
    transform: scaleY(0);

    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    a {
        color: $primary;
    }

    .desktop--menu-item {
        display: block;
        padding-left: 15px;

        &.is-header {
            margin-bottom: 14px;
            padding-left: 15px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 5px;
                height: 22px;
                background-color: #ce3249;

                border-radius: 3px;
            }
        }
    }

    .desktop--menu-column {
        opacity: 0;
        transform: translateY(-10px);
        transition: transform 0.1s, opacity 0.1s;
        transition-delay: 0s;
    }
}


.footer-primary {

    // border-top: 1px solid $border-color;
    background-color: #1b1b1b;


    .socials{
        margin: 20px 0 0;
        padding: 0;
        display: flex;

        li{
            display: inline-block;
            margin-right: 15px;

            >a{
                width: 54px;
                height: 54px;
                border-radius: 50%;
                background-color: #222326;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .footer-wraper {
        // background-color: $primary;
        // color: color-yiq($primary);

        padding-top: $footer-padding;
        padding-bottom: $footer-padding;
    }

    a {
        color: color-yiq(#000);

        &:hover{
            color: $link-footer-color
        }
    }

    h3 {
        font-size: 18px;
        // color: darken(color-yiq($primary), 7.5%);
    }

    .footer-bottom {

        background-color: #363636;
        // color: color-yiq($primary);
        // color: $body-light-color;
        color: #fff;
        font-size: 12px;
        padding-top: 15px;
       padding-bottom: 15px;

        .footer-bottom-wrap{

            // border-top: 2px solid #000;
        }

        nav{
            display: flex;

            a{
                margin-right: 25px;
                display: block;

                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }

    .app-download {
        background-image: url("../images/58663-app-google-play-store-apple-download-hd-png-thumb.png");
        background-size: auto;
        background-repeat: no-repeat;

        display: block;
        width: 180px;
        height: 52px;

        &.android {
            background-position: -10px -42px;
        }

        &.ios {
            background-position: -10px -105px;
        }
    }

    dt {
        // color: rgba(255, 255, 255, 0.6);
        color: #919496;
        font-weight: normal;
        text-transform: uppercase;

        margin-bottom: 8px;
        font-weight: 500;
    }
}

// .banner-topic,
// .home-banner-swiper{
//     padding-top: 30px !important;
// }


.banner-topic-swiper{
    margin-right: -15px;

    .banner-topic-card{
        padding-right: 15px;
        margin-right: 0 !important;
    }
}


.banner-topic {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $primary;

    .banner-topic-container {
        position: relative;

        z-index: 1;
    }
    .swiper-slide {
        position: relative;
        // overflow: hidden;
    }

    .banner-topic-body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // z-index: 5;

        background: rgba(0, 0, 0, 0.8);
        // backdrop-filter: saturate(180%) blur(5px);

        // box-shadow: 0 0 0 -2px rgba(0,0,0,.1);

        &:hover {
            text-decoration: none;
        }
    }

    .topic-card{
        background-color: $border-color;
        position: relative;
        height: 0;
        padding-top: 56.25%;
        // border-radius: 12px;

        .banner-topic-title{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            text-transform: uppercase;
        }
    }


    .banner-topic-pic {
        // padding-top: 56.25%;
        padding-top: 100%;
        height: 0;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .banner-topic-title {
        padding: 10px;
        font-size: 1rem;
        font-weight: bold;
        margin: 0;

        // background-color: $primary;
        color: #fff;
        position: relative;
        border-radius: 3px;

        // &::before{
        //     content: '';
        //     border: 2px solid #fff;
        //     position: absolute;

        //     top: -10px;
        //     bottom: -10px;

        //     left: -20px;
        //     right: -20px;
        // }
    }

    img {
        width: 100%;
    }
}

.banner-topic--border-topleft {
    width: 10%;
    height: 30%;
    position: absolute;
    top: 30%;
    left: 5%;
    border-width: 1px 0px 0 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
}
.banner-topic--border-bottomleft {
    width: 10%;
    height: 30%;
    position: absolute;
    bottom: 30%;
    left: 5%;
    border-width: 0 0px 1px 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
}
.banner-topic--border-topright {
    width: 10%;
    height: 30%;
    position: absolute;
    top: 30%;
    right: 5%;
    border-width: 1px 1px 0 0;
    border-style: solid;
    border-color: rgb(255, 255, 255);
}
.banner-topic--border-bottomright {
    width: 10%;
    height: 30%;
    position: absolute;
    bottom: 30%;
    right: 5%;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: rgb(255, 255, 255);
}

.post-image--poster,
.post-image {
    width: 100%;
    height: 0;
    // padding-top: 144%;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;

    display: block;

    background-color: #f2f2f2;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100%;
        object-fit: cover;
    }
}

.post-image--poster {
    padding-top: 144%;
    background-color: rgba(0, 0, 0, 0.5);
}
.home-banner-swiper .post-slide--content,
.post-image--poster {
    // border-radius: 6px;
}
@media (min-width: 1400px) {
    .home-banner-swiper .post-slide--content,
    .post-image--poster {
        // border-radius: 10px;
    }
}

.home-banner-swiper {
    background-color: darken($primary, 7.5%);
    overflow: hidden;

    &.module {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .post {
        position: relative;
    }

    .post-image--poster {
        .image {
            height: 100%;
        }
    }

    .post-slide--content {
        background: linear-gradient(180deg, rgba(25, 28, 33, 0) 55.21%, rgba(25, 28, 33, 0.6) 74.48%, #191c21);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform 0.2s ease, padding 0.2s ease;
        pointer-events: none;
        z-index: 1;

        &:hover {
            cursor: default;
        }

        .post-body--category-link {
            color: #fff;
            font-size: 12px;
            font-weight: normal;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .post-content {
        padding: 15px;
        color: color-yiq($primary);
        // background: rgba(14,13,11,0.63);
        display: block;
        position: absolute;

        z-index: 1;
        user-select: none;
        pointer-events: auto;

        width: 100%;
        left: 0;
        bottom: 0;

        // background: rgb($primary, 0.8);
        // backdrop-filter: saturate(180%) blur(20px);

        // background: rgba(0,0,0,.7);
        // background: linear-gradient(to top,rgba($primary,1),rgba($primary,.1));
        // background: linear-gradient(to top, $primary 0, $primary 75%, transparent 100%);

        .post-title,
        .post-subtitle {
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
        }

        .post-title {
            -webkit-line-clamp: 2;
            font-size: 1.2rem;
            font-weight: 500;

            a {
                color: color-yiq($primary);

                &:hover {
                    text-decoration: none;
                    // color: $primary;
                }
            }
        }
        .post-subtitle {
            -webkit-line-clamp: 3;
        }
    }

    .home-banner-swiper-controls {
        position: absolute;
        // width: 100%;
        left: 30px;
        right: 30px;
        bottom: -6px;
        z-index: 1;
        height: 2.58824rem;
        display: none;

        .container,
        .container-fluid {
            position: relative;
            height: 100%;
        }
    }

    .home-banner-swiper-control {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            border-radius: 3px;
            // transition: background-color 250ms ease, width 500ms ease;
        }
        .swiper-pagination-bullet + .swiper-pagination-bullet {
            margin-left: 8px;
        }

        .swiper-pagination-bullet-active {
            background-color: #fff;

            width: 24px;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        // opacity: 0;
        width: 2.58824rem;
        height: 2.58824rem;
        color: #fff;
        text-align: center;
        border: 2px solid #fff;
        border-radius: 50%;
        background-color: transparent;
        transition: all 0.25s ease 0s;
        right: 0;
        left: auto;
        top: auto;
        bottom: 0;
        font-size: 20px;

        &:after {
            display: none;
        }

        &:hover {
            background-color: #fff;
            color: $primary;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5), 0 1px 10px rgba(0, 0, 0, 0.1);
        }
    }

    .swiper-controls--next,
    .swiper-controls--prev {
        display: none;
    }

    .swiper-button-prev {
        right: 55px;
    }

    // .swiper-button-next{

    //     left: 100%;
    //     right: auto;
    // }

    &:hover {
        .swiper-button-prev,
        .swiper-button-next {
            opacity: 1;
        }
    }
}

.desktop-menu-container {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
}
.desktop-menu {
    width: calc(100% - 426px);
    margin: 0 auto;
    // height: 60px;
    overflow: hidden;
    // max-width: 1255px;
    max-width: 740px;

    list-style: none;

    display: flex;
    margin: 0 auto;
    padding: 0;
    justify-content: center;

    // background: #f2f2f2;
}
@media only screen and (min-width: 1440px) {
    .desktop-menu {
        // width: calc(100% - 880px);
        max-width: 860px;
    }
}

.desktop-menu-item {
    // margin: 0 auto;
    cursor: pointer;
    list-style: none;

    &:hover {
        .desktop-menu-dropdown {
            opacity: 1;
            visibility: visible;
            transform: scaleY(1);
        }

        .pre-menu-column {
            opacity: 1;
            transform: translateY(0);
            transition: transform 0.3s ease, opacity 0.3s linear;
            transition-delay: 0.2s;
        }

        .desktop-menu-link {
            border-bottom-color: $primary;
        }
    }
}
.desktop-menu-link {
    padding: 8px 12px;
    border-bottom: 4px solid transparent;
    transition: border-color 0.1s, color 0.1s;
    font-size: 20px;
    font-weight: 600;
    display: block;

    &:hover {
        text-decoration: none;
    }
}

.desktop-menu-dropdown {
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    overflow: hidden;
    max-height: 800px;
    top: 100%;
    left: 0;
    right: 0;
    transition: transform 0.1s ease, opacity 0ms linear 0.1s, visibility 0ms linear 0.1s;
    transform-origin: top center;
    transform: scaleY(0);

    background-color: #fff;
    width: 100%;
    padding: 30px;
}

.pre-menu-column {
    // display: inline-block;
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.1s, opacity 0.1s;
    transition-delay: 0s;
}
.pre-menu-item {
    text-align: left;
    background: transparent;
    display: block;
    margin-bottom: 6px;
    white-space: normal;
    overflow-wrap: normal;
    overflow: hidden;

    padding-left: 20px;

    color: #757575;
}
.pre-menu-item.is-header {
    color: #111;
    margin-bottom: 14px;
    margin-top: 40px;
    padding-left: 14px;

    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 22px;
        background-color: $primary;
        // transform: translateY(-50%);

        border-radius: 3px;
    }
}
.pre-menu-column .is-header:first-child {
    margin-top: 0;
}

.pre-search-input-box {
    position: relative;
    padding: 7px 0;
    // float: right;
    // margin: 0 74px 0 12px;
}
.pre-search-input {
    // background: transparent;
    // visibility: hidden;
    // color: transparent;
    border-radius: 100px;
    // padding: 8px 56px 8px 0;
    // height: 40px;
    // width: 40px;
    // cursor: pointer;
}
.pre-clear-search {
    display: none;
}
.pre-search-btn {
    position: absolute;
    top: 0;
    left: 2px;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    background: transparent;
}

@media only screen and (min-width: 1024px) {
    .pre-search-input-box {
        width: 180px;
        // position: absolute;
        // z-index: 1;
        // right: 0;
        // float: right;
        // margin: 0;
        // transform: translateX(0);
        transition: width 0.25s, transform 0.35s;
    }

    .pre-search-input {
        visibility: visible;
        width: 100%;
        background: #f5f5f5;
        color: inherit;
        padding: 8px 48px;
        cursor: text;
    }

    .pre-search-btn {
        top: 8px;
        left: 2px;
        padding: 6px 8px;
    }
}

.module {
    position: relative;
}

.featured-article-container {
    // position: relative;
    // overflow: hidden;

    .module-header-inner {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .swiper-container {
        overflow: visible;
    }
}
.featured-article-wrap {
    // padding-bottom: 40px;
    position: relative;

    .swiper-slide {
        width: 80%;
        margin-right: 15px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 40px;
        height: 40px;
        right: 0;
        left: auto;
        border-radius: 50%;
        //
        margin-top: 0px;
        top: -50px;
        font-size: 20px;
        color: $primary;
        border: 2px solid $primary;

        &:after {
            display: none;
        }

        &:hover {
            background: #fff;
        }

        &:focus {
            outline: none;
        }
    }

    .swiper-button-prev {
        right: 50px;
    }
}

.post-title-link {
    &:hover {
        text-decoration: none;
    }
}

.post-more-link {
    letter-spacing: 1.2px;
}

.post-category {
    position: relative;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;

    a:hover {
        text-decoration: none;
    }

    .underline {
        position: absolute;
        display: block;
        background-color: #fff;
        left: 0;
        bottom: 3px;
        // top: 100%;
        width: 80%;
        height: 3px;
        border-radius: 3px;
    }
}

.article {
    .figure-wrap {
        margin-left: -15px;
        margin-right: -15px;

        .figure{
            background-color: #fff;
        }
    }

    .post-content {
        background-color: #fff;
        // box-shadow: 0 0 12px rgba(0,0,0,.03);
        border-radius: $card-border-radius;
        overflow: hidden;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
    }

    .post-footer,
    .post-body {
        padding: 0.5rem 1rem;
    }

    .post-body--category {
        padding-left: 1rem;
        padding-right: 1rem;

        font-size: 12px;
    }

    .post-title {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;

        // font-size: 1.8rem;
        // color: #fff;
    }

    .post-title {
        -webkit-line-clamp: 2;
        font-weight: bold;
        font-size: 20px;
        // margin: 0;
        height: 48px;

        a {
            display: block;
        }
    }
}

.home-banner-middle {
    // height: 250px;
    background-color: $primary;

    img {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .module {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media (min-width: 992px) {
    .module {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.home-featured {
    position: relative;
    overflow: hidden;
    background-color: #d8d8d8;
}

@media only screen and (min-width: 1024px) {
    .fw-background {
        position: absolute;
        top: -32px;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $primary;
        transform: skew(0, -7.5deg);
        transform-origin: bottom left;
    }

    .fw-bg-wrap {
        background-color: transparent !important;
    }

    .banner-topic {
        z-index: 2;
        // padding-bottom: 350px;
    }

    .module-outer:first-child {
        padding-top: 0;
    }

    .module-header-outer {
        position: relative;
        // min-height: 300px;
        padding-top: 30px;
        padding-bottom: 180px;

        .module-header-inner {
            max-width: 50%;
        }

        h3 {
            font-weight: bold;
            letter-spacing: 2.4px;
        }

        .btn {
            border-color: #fff;
        }

        .container {
            position: relative;
            z-index: 1;
            color: #fff;
        }
    }

    .module-header-outer + .container {
        margin-top: -170px;
    }

    .home-featured {
        padding-bottom: 100px;

        background-image: url("../images/bg-2.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-attachment: fixed;

        &::after {
            content: "";

            position: absolute;
            bottom: -300px;
            left: 0;
            width: 100%;
            height: 300px;
            background-color: $primary;
            transform: skew(0, -7.5deg);
            transform-origin: bottom left;
        }

        &.style-2 {
            background-image: url("../images/bg-3.png");

            .fw-background {
                background-color: $gold;
                //  #
            }

            .featured-article-wrap .swiper-button-prev,
            .featured-article-wrap .swiper-button-next {
                color: $gold;
                border-color: $gold;
            }

            &::after {
                background-color: $gold;
            }
        }

        &.style-3 {
            background-image: none;

            .fw-background {
                background-color: $orange;
                //  #
            }

            .featured-article-wrap .swiper-button-prev,
            .featured-article-wrap .swiper-button-next {
                color: $orange;
                border-color: $orange;
            }

            &::after {
                background-color: $orange;
            }
        }
    }

    .featured-article-wrap {
        .swiper-container {
            padding-top: 20px;
            padding-bottom: 20px;
            overflow: hidden;

            .swiper-slide {
                width: 25%;
                margin-right: 25px;
            }
        }
    }

    .article {
        .post-content {
            &:hover {
                box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.13);
            }
        }
    }
}

.banner-topic-wrap {
    overflow: hidden;

    .swiper-slide {
        width: 50%;
        margin-right: 10px;
    }
}

.module-image-header.module-image-footer {
    min-height: 500px;
}
.module-image-footer {
    position: relative;
    min-height: 300px;
    overflow: hidden;
}
.module-head {
    padding-left: 14px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 32px;
        background-color: $primary;
        border-radius: 4px;
    }
}

.post-figure {
    width: 100%;
    background-color: rgb($primary, 0.4);
    height: 0;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    display: block;

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100%;
        object-fit: cover;
    }

    .post-figure-category {
        position: absolute;
        bottom: 8px;
        left: 9px;
        padding: 1px 8px;
        background-color: #000;
        color: #fff;
        display: block;
        font-size: 13px;
        border-radius: 2px;
    }
}

.banner-horizontal {
    position: relative;
    background-color: $primary;
    overflow: hidden;

    img {
        width: 100%;
    }
    .image {
        // background: url(https://www.apple.com/newsroom/images/live-action/keynote-november-2020/Apple_eventbranding-11102020.jpg.branding-large.jpg) no-repeat top;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-color: rgb($primary, 0.4);
    }

    .banner-container {
        padding-top: 24px;
        padding-bottom: 24px;

        position: relative;
        z-index: 1;

        color: color-yiq($primary);
    }
    .text-editor {
        a {
            color: #fff;
        }
    }
}

.filter-wrap {
    width: 100%;
    // height: 72px;
    background-color: #fff;
}

.filter-bar {
    display: flex;
    align-items: center;

    margin-left: -15px;
    margin-right: -15px;
}

.filter-item {
    padding-top: 10px;
    padding-bottom: 10px;

    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-label {
    font-weight: 500;
}

.footer-list {
    li {
        display: list-item;
    }
}

.footer-brand--dcu {
    background: #fff;
    padding: 8px 15px;
    border-radius: 8px;
}

@media only screen and (min-width: 1024px) {
    .module-image-header {
        .container {
            position: relative;
            z-index: 1;
        }

        .module-header {
            .module-header-title,
            .module-header-subtitle {
                margin-left: -8px;
                margin-right: -8px;

                span {
                    background-color: $primary;
                    color: color-yiq($primary);

                    display: inline-block;
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 300px;
            background-color: $primary;
            transform: skew(0, -7.5deg);
            transform-origin: bottom left;
            // z-index: -1;
            pointer-events: none;
        }
    }

    .module-image-footer::after {
        content: "";
        position: absolute;
        bottom: -300px;
        left: 0;
        width: 100%;
        height: 300px;
        background-color: $primary;
        transform: skew(0, -7.5deg);
        transform-origin: bottom left;
        // z-index: -1;
        pointer-events: none;
    }

    // .banner-horizontal{
    //     .image{
    //         height: 320px;
    //     }
    // }

    .post:hover {
        .post-figure {
            .image {
            }
        }
    }

    .post-figure {
        .image {
            transition: transform 400ms cubic-bezier(0.4, 0, 0.25, 1), opacity 1s cubic-bezier(0.4, 0, 0.25, 1);
        }
        // opacity: 1;
        // -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.25, 1),-webkit-transform 400ms cubic-bezier(0.4, 0, 0.25, 1);
        // transition: opacity 1s cubic-bezier(0.4, 0, 0.25, 1),-webkit-transform 400ms cubic-bezier(0.4, 0, 0.25, 1);

        // transition: transform 400ms cubic-bezier(0.4, 0, 0.25, 1),opacity 1s cubic-bezier(0.4, 0, 0.25, 1),-webkit-transform 400ms cubic-bezier(0.4, 0, 0.25, 1);

        // width: 100%;
        // height: 100%;
        // background: rgba(0,0,0,0);
        // position: absolute;
        // top: 0;
        // left: 0;
        // z-index: 2;
        // -webkit-transition: background 400ms cubic-bezier(0.4, 0, 0.25, 1);
        // transition: background 400ms cubic-bezier(0.4, 0, 0.25, 1);
    }
}

.posts-grid {
    margin-left: 0;

    .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
        padding-left: 0;
    }


    .post-body {
        padding-top: 0.5rem;
    }
    .post-footer,
    .post-meta {
        display: none;
    }

    .post-title {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;

        -webkit-line-clamp: 2;
        font-weight: bold;
        font-size: 14px;

        height: 32px;
    }

    .post-title-link {
        color: $primary;
    }

    .post-category,
    .post-body--category {
        font-size: 12px;
    }

    .post-category {
        padding-left: 8px;
        padding-bottom: 0;

        .underline {
            width: 3px;
            height: 12px;
            top: 50%;
            bottom: auto;
            margin-top: -8px;
            border-radius: 0;
        }
    }
}

.pagination {
    margin-bottom: 0;
    padding: 6px;

    background-color: #f5f5f7;
    border-radius: 25px;
    // min-width: 760px;
    justify-content: center;

    .page-item {
        margin-left: 5px;
        padding-right: 5px;
    }
    .page-link {
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        padding: 0;
        line-height: 36px;
        text-align: center;
        background-color: #fff;
        font-size: 16px;

        border-width: 0;

        &:hover {
            background-color: rgba($primary, 0.5);
            color: color-yiq($primary);
        }
    }
    .disabled {
        .page-link {
            // background-color: rgba($primary, .1);
            opacity: 0.5;
        }
    }
}

.table-video {
    width: 100%;

    td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .table-video-status {
        font-size: 30px;
    }

    .progress {
        height: 8px;
        border-radius: 6px;
    }
    .figure {
        border-radius: 3px;
    }

    .table-video-title {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.21;
        letter-spacing: normal;
    }

    .table-video-meta {
        line-height: 1;
    }
}

.login-container {
    background-color: $primary;

    .card {
        background-color: #fff;
        border-radius: 0.25rem;

        border-width: 0;

        max-width: 31.25rem;
        margin: 0 auto;
    }

    .card-header {
        padding-top: 1.875rem;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        font-size: 1.25rem;
        color: #222;
        text-transform: capitalize;

        font-weight: bold;

        border-bottom-width: 0;
    }

    .card-body {
        padding: 1.875rem;
    }
}
.login-divider{
    display: flex;
    align-items: center;
    padding-bottom: .875rem;

    .login-divider-before,
    .login-divider-affter{
        height: 1px;
        width: 100%;
        background-color: #dbdbdb;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .login-divider-text{
        color: #ccc;
    padding: 0 1rem;
    text-transform: uppercase;
    font-size: .75rem;
    }
}

.checkout-wizard {
    margin-left: auto;
    // margin: 0 auto;
    // padding-bottom: 24px;
}
@media (max-width: 575px) {
    .checkout-wizard {
        width: auto;
        padding-bottom: 0;
    }
}
.checkout-wizard .step {
    display: flex;
    position: relative;
    color: #b2b2b4;
}
.checkout-wizard .step:not(:last-child) {
    flex-grow: 1;
    padding-right: 8px;
}
.checkout-wizard .step:not(:last-child):after {
    content: "";
    border-bottom: 2px dotted #b2b2b4;
    height: 1px;
    flex-grow: 1;
    min-width: 32px;
    margin-left: 8px;
    margin-top: 16px;
}
@media (max-width: 575px) {
    .checkout-wizard .step:not(:last-child):after {
        margin-top: 10px;
    }
}
.checkout-wizard .step .step-label {
    position: relative;
}
@media (max-width: 575px) {
    .checkout-wizard .step .step-label {
        display: flex;
        align-items: center;
        font-size: 12px;
    }
}
@media (min-width: 575px) {
    .checkout-wizard .step .step-label .text-action {
        position: absolute;
        transform: translate(-50%);
        left: 50%;
        width: 120px;
        text-align: center;
    }
}
.checkout-wizard .step.success,
.checkout-wizard .step.success a {
    color: #000106;
}
.checkout-wizard .step.success .number-step,
.checkout-wizard .step.success a .number-step {
    border: 2px solid $primary;
    color: $primary;
    background: #fff;
    line-height: 26px;
}
@media (max-width: 575px) {
    .checkout-wizard .step.success .number-step,
    .checkout-wizard .step.success a .number-step {
        line-height: 18px;
    }
}
.checkout-wizard .step.success .number-step i,
.checkout-wizard .step.success a .number-step i {
    margin: 0 auto;
}
.checkout-wizard .step.success:after,
.checkout-wizard .step.success a:after {
    border: 1px solid $primary;
}
.checkout-wizard .step.active {
    color: #000106;
}
.checkout-wizard .step.active .number-step {
    background: $primary;
    color: #fff;
}
.checkout-wizard .number-step {
    width: 30px;
    height: 30px;
    background: #ebebeb;
    border-radius: 50%;
    text-align: center;
    color: #b2b2b4;
    line-height: 30px;
    margin: 0 auto;
}
@media (max-width: 575px) {
    .checkout-wizard .number-step {
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin-bottom: 0;
        margin-right: 4px;
    }
}
.checkout-wizard ul {
    justify-content: space-between;
}
.checkout-wizard ul li {
    list-style: none;
}

.checkout-wizard .step {
    &.disabled {
        opacity: 0.5;
        cursor: default;
    }

    .number-step {
        &:hover {
            cursor: pointer;
        }
    }
}

.app-sidebar-nav {
    background-color: #fff;
    border-radius: $card-border-radius;

    padding-bottom: 8px;

    .app-sidebar-nav-profile .group-name {
        text-align: center;
        padding: 40px 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .app-sidebar-nav-profile .avatar:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .app-sidebar-nav-profile .avatar {
        width: 90px;
        height: 90px;
        margin-bottom: 8px;
        overflow: hidden;
        position: relative;
    }

    .app-sidebar-nav-profile .name {
        display: flex;
    }
}

.app-sidebar-nav-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.app-sidebar-nav-link {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    //
    color: #3f3f3f;

    &:hover {
        text-decoration: none;
        background-color: #f8f8f8;
    }

    &.active {
        background-color: rgba($primary, 0.1);
        color: $primary;
    }
}
.app-sidebar-nav-count {
    color: #b2b3b3;
}

.featured-header {
    // margin-bottom: 20px;
    .category-eyebrow__divider {
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.6);
        width: 1px;
        height: 1em;
        display: inline-block;
        margin: 0 10px;
    }

    .featured-headline {
        font-weight: normal;
        font-size: 32px;
        line-height: 1.375;

        padding-left: 15px;
        padding-right: 15px;
    }

    // .category-eyebrow__category::after {
    //     content: '';
    //     display: inline-block;
    //     height: 1em;
    //     width: 1px;
    //     margin: 0 10px;
    //     background-color:rgba(255,255,255,.6);
    // }

    .featured-subhead {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;

        padding-left: 15px;
        padding-right: 15px;

        margin-left: auto;
        margin-right: auto;
        max-width: 653px;
    }
}

@media (min-width: 576px) {
    .header-topbar--dropdown-menu {
        width: auto;
        max-width: 400px;
        width: auto;
        max-width: 400px;
        width: 260px;
        padding: 0.5rem;

        box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.12), 0 -1px 2px 0 rgba(0, 0, 0, 0.08);
        border-width: 0;

        // color: $body-color;

        .dropdown-item {
            padding: 8px;
            // background-color: #f8f8f8;

            border-radius: 5px;
            // color: $body-color;
        }
    }
}

.b-default-avatar {
    background-color: rgba(138, 150, 163, 0.12);
    height: 100%;
    width: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
}

.video-thumbnail-wrap {
    .runtime {
        position: absolute;
        right: 6px;
        bottom: 6px;
        z-index: 1;
        font-size: 12px;
        color: #fff;
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        line-height: 14px;
    }
    .play-bars {
        position: absolute;
        left: 6px;
        bottom: 6px;
        z-index: 1;
        font-size: 12px;
        color: #fff;
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        line-height: 14px;
    }

    .video-thumbnail-status {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        font-size: 12px;
        padding: 4px 6px;
        line-height: 12px;
    }
}

@media print {
    body {
        margin: 0;
        color: #000;
        background-color: #fff;

        .header-primary,
        .footer-primary,
        .print-hide {
            display: none !important;
        }

        .card {
            border: 1px solid #000;
        }

        table {
            width: 100%;

            th,
            td {
                background: none !important;
                border-color: #ccc !important;
            }
        }

        .col-print {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .featured-headline {
            color: $primary !important;
        }
    }
}

@media (max-width: 540px) {
    .category-eyebrow-scroll {
        max-width: 100%;
        height: 2.5rem;
        overflow: hidden;

        .category-eyebrow {
            display: block !important;

            padding-bottom: 2rem;
            padding-left: 15px;
            padding-right: 15px;
            overflow-x: auto;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;

            > div {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .td-mobile-none {
        display: none;
    }
}


@media only screen and (min-width: 1024px) {

    .feed-style-1, .feed-style-2{
        background-repeat: no-repeat;
        background-size: 100%;
        background-attachment: fixed;
    }
    .feed-style-1 {
        background-image: url(/images/bg-2.png);
    }

    .feed-style-2{
        background-image: url(/images/bg-3.png);
    }
}

.desktop-nav-item.dropdown:hover{
    .desktop-dropdown{
        display: block;
    }
}

.desktop-dropdown{
    display: none;
    position: absolute;
    z-index: 1000;

    top: 100%;
    left: 0;

    .desktop-dropdown-wrap{
        position: relative;
        width: 100%;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
        color: #3c3b37;
    }
    .menu-container{
        display: flex;

        .menu-nav{
            border-left: 1px solid rgba(0,0,0,.1);
            padding: 8px 0;
            position: relative;
            width: 260px;
            min-height: 240px;

            &:first-child {
                border-left: 0;
            }

            >ul{
                list-style: none;
                margin: 0;
                padding: 0;
                max-width: none;

                >li{
                    padding-left: 0;

                    >a{
                        color: #3c3b37;
                        border-radius: 0;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: auto;
                        text-align: left;
                        letter-spacing: normal;
                        white-space: normal;
                        padding: 4px 12px;

                        &:hover{
                            background-color: rgba(0, 0, 0, 0.01);
                            text-decoration: none;
                        }

                        span{
                            flex: 1;
                            min-width: 1px;
                            display: block;
                        }
                        svg{
                            width: 16px;
                            height: 16px;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }


    }
}
.dropdown-popper{
    opacity: 0;
    margin-top: .4rem;
    animation: popper--pop-in 100ms cubic-bezier(.2,0,.38,.9) forwards;
}

@keyframes popper--pop-in{
    0%{opacity:0;transform:scale(.9)}100%{opacity:1;transform:scale(1)}
}

.btn-facebook{
    color: color-yiq($facebook-bg);
    background-color: $facebook-bg;
    border-color: $facebook-bg;

    &:hover,
    &.hover{
        color: color-yiq($facebook-bg);
        background-color: darken($facebook-bg, 7.5%);
        border-color: darken($facebook-bg, 10%);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($facebook-bg, .5);
    }

    &:active,
    &.active{
        color: color-yiq($facebook-bg);
        background-color: darken($facebook-bg, 10%);
        border-color: darken($facebook-bg, 12.5%);
    }
}

.btn-google{
    color: color-yiq($google-bg);
    background-color: $google-bg;
    border-color: #4285f4;

    &:hover,
    &.hover{
        color: color-yiq($google-bg);
        background-color: $google-bg;
        border-color: darken(#4285f4, 10%);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba(#4285f4, .5);
    }

    &:active,
    &.active{
        color: color-yiq($google-bg);
        background-color: $google-bg;
        border-color: darken(#4285f4, 12.5%);
    }
}
.btn-apple{
    color: color-yiq($apple-bg);
    background-color: $apple-bg;
    border-color: #000;

    &:hover,
    &.hover{
        color: color-yiq($apple-bg);
        background-color: $apple-bg;
        border-color: #000;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba(#000, .5);
    }

    &:active,
    &.active{
        color: color-yiq($apple-bg);
        background-color: $apple-bg;
        border-color: #000;
    }
}
