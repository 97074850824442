

.player-plyr {
    width: 100%;
    height: 0;
    background-color: #000;
    color: #fff;
    overflow: hidden;
    position: relative;
    padding-top: 56.25%;
}

.player-plyr--player,
.player-plyr--error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-thumbnail-wrap {
    width: 128px;
    position: relative;


    &:hover{
        .video-thumbnail-control {
            opacity: 1;
        }
    }
}

@media (min-width: 768px){
    .video-thumbnail-wrap {
        width: 300px;
    }
}
.video-thumbnail {
    width: 100%;
    height: 0;
    background-color: #c3cfe2;
    color: #113466;
    overflow: hidden;
    position: relative;
    padding-top: 56.25%;

    img {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}

.video-thumbnail-control {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.35);
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    z-index: 1;
}

.video-thumbnail-control--play {
    margin: 0;
    padding: 0;

    display: inline-flex;
    // background-color: rgba(216, 216, 216, 0.3);
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    width: 44px;
    height: 44px;
    border-radius: 22px;

    font-size: 44px;
    color: #fff;

    // background-color: transparent;
    // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAAEbUlEQVR4Ae3bX2gdWR0H8JGkMV2IN9oqXcnasi6yC+qqD+4KSqukIoIbkfVJcPdR8aFFRQSVKFoolFJp6UNBDb70oRVTRVRQSR/qH0SaphJttTGtNopiuWmSJt5755yPL1IuCHpz58zcFOZ3nhPmQ+Y3M+d3vsk84qsGlLVqQA2oATWgBtSAGlADakAN2G4ZccCLTviReSuaVq1aseDHjvuQJwxn26oKAV7jBefcsKatIxfEhyvIdbTd91tnHTa2owCGvdUptzyQC4Lov6ubsukPvuYpQzsC4Dnn/VMuiqLeKur4u295ZsAAr3XaPUHUXfNmTDuoe02bMU83Qu4fjtkzIIAhH3RDRwTQNGNK43/8TMOUGc0uRNuCSUOVA4w7a0MEsOwljayn0vCS5S7EujNGKwV4nV9pix5efLbt6kJEbXNeVRnA05bkImBaI+urNEx39cNNE5UATFgRRLDs2axQedYyiIIl+0oHmHBHEMGsRla4NMwCgiWPlwow4vcCYCZLVmZAFMwbLQ1gxPfkYvflJyd0XDBaFuAzWt2XXxJhyydKAXjaPRHMZ6WUeRCteLIMwA8FEU37SwLs1wTBxeQAk7YAU1lpZQpEWyaTAgy7LoK5rNQyB4JrRlMCPqYF/P/bx22HCt1GEG36aDKAx1zt/ekDLhkv+DQKLhtNBXiPdcD+HgGsOlrwb7DquVSAbwhgNuuhAHDZ2/oizCLKnUoC0PAXEUxtEwBfNt7nsyi4YXcKwPttiWhmWR8A/TS1JqINb08BOK4DZvsEwCUH+riJOj6XAnBZAEcKALbZ1I6A4PspAHcFcLAQAK712tQOguCPhQEesy5AlhUGwNd7a2oQNe0qCjjgXyKWEwG47cM9/JZlRJv2FgUc0hYxlwzQU1ObQ9Ty5qKAjycG9NDUXYC2w0UBR5IDemjqLsCLRQFHBwz4yKN9C7W871Fv4meKAp7UGuhjdE+KF1kc0IssJHiRZZk/D/BT4qasOOBnA/yYu5gC8BUdsfDn9Pi2P6fp+FQKwLs8GNCGZs1bUgDG/EkYwJYy+p3RNJv6c3Kx4k09uROpphKHrIsVj1Voeke6wdavhYoHW9FPjKQbLb5sq9LRIpteSD3cDZUOd39pOO14/b22xIrG62x4d/oDju8KVHDAEeW+XcYJzRutVHLEFNzxhnIO+T5pq4JDvgdeLuuUctSF0o9Zc9+0Kzmgi7AgpCeYAeSu2FVu1OD1lgRKiBpEwXV7yw97PG6plLBHbtHeauI2ExZLiNss2Fdd4GnMTxMGnoKWH9hdbeTslU5aSxI5C+47ZngQob9JV7ULhv5arnjn4GKXe3zR33T6jl3e9XmvHnTw9Sln/FVnG6HXKGi766SJnRE9HvImX7Boozsx/XABEAVBx5p5n7UvvGJnhb/HHHbaNataOvL/rCAIcrmOlqbf+Krn7a4+/N1jGfaED/iS7/iFRbcsuWXRz533ac93N3hRQP0fHDWgBtSAGlADakANqAE1oAbUgH8Dl4A0+61z7D0AAAAASUVORK5CYII=);
    // background-repeat: no-repeat;
    // background-size: cover;

}

@media only screen and (min-width: 735px) {
    .video-thumbnail-control--play {
        width: 54px;
        height: 54px;
        border-radius: 27px;

        // &:before {
        //     background-size: 11px 12px;
        // }
    }
}

.modal-video{
    .video-thumbnail-wrap
    {
        width: 160px;
    }

    .active{
        .video-thumbnail-control{
            // opacity: 1;
        }
    }

}


.play-bars{

    width: 18px;
    height: 18px;
    position: relative;
    // margin: 10px auto 0;

    .bar{
        background: #fff;
        bottom: 0;
        height: 30px;
        position: absolute;
        width: 3px;
        animation: sound-bars 0ms -800ms linear infinite alternate;
    }

    .bar:nth-child(1) {
        left: 1px;
        animation-duration: 474ms;
    }
    .bar:nth-child(2) {
        left: 5px;
        animation-duration: 433ms;
    }
    .bar:nth-child(3) {
        left: 9px;
        animation-duration: 407ms;
    }
    .bar:nth-child(4) {
        left: 13px;
        animation-duration: 458ms;
    }
}

@keyframes sound-bars{0%{opacity:0.35;height:3px;}100%{opacity:1;height:13px;}}

//   ;}.lang-en .subTitle.collapsed.jsx-2533519907{max-width:660px;padding:5px 80px 0 20px;}.isMobile .subTitle.collapsed.jsx-2533519907{padding-left:10px;}.readMore.jsx-2533519907{font-size: 1em ;;display:inline-block;cursor:pointer;color:#bcbcbc;-webkit-transition:color .2s;transition:color .2s;}.collapsed.jsx-2533519907 .readMore.jsx-2533519907{position:absolute;right:0;bottom:0;}.isDesktop .readMore.jsx-2533519907:hover,.isDesktop .readMore.jsx-2533519907:focus{color:#fff;}.readMore.jsx-2533519907 i.jsx-2533519907{font-size: 0.625rem ;;position:static;}</style><style id="__jsx-1439626454">.SongListCount.jsx-1439626454{text-align:center;}.bars.jsx-1439626454{width:18px;position:relative;margin:10px auto 0;}.bar.jsx-1439626454{background:#fff;bottom:0;height:30px;position:absolute;width:3px;-webkit-animation:sound-jsx-1439626454 0ms -800ms linear infinite alternate;animation:sound-jsx-1439626454 0ms -800ms linear infinite alternate;}@-webkit-keyframes sound-jsx-1439626454{0%{opacity:0.35;height:3px;}100%{opacity:1;height:13px;}}


//   .bar.jsx-1439626454:nth-child(1){left:1px;-webkit-animation-duration:474ms;animation-duration:474ms;}.bar.jsx-1439626454:nth-child(2){left:5px;-webkit-animation-duration:433ms;animation-duration:433ms;}.bar.jsx-1439626454:nth-child(3){left:9px;-webkit-animation-duration:407ms;animation-duration:407ms;}.bar.jsx-1439626454:nth-child(4){left:13px;-webkit-animation-duration:458ms;animation-duration:458ms;}.isDesktop .SongListItem:hover .bars.jsx-1439626454,.isDesktop .SongListItem:hover .count.jsx-1439626454{display:none;}.btnPlay.jsx-1439626454,.btnPause.jsx-1439626454{display:none;-webkit-animation:fadein-jsx-1439626454 1s;animation:fadein-jsx-1439626454 1s;}@-webkit-keyframes fadein-jsx-1439626454{from{opacity:0;}to{opacity:1;}}@keyframes fadein-jsx-1439626454{from{opacity:0;}to{opacity:1;}}.isDesktop .SongListItem:hover .btnPlay.jsx-1439626454,.isDesktop .SongListItem:hover .btnPause.jsx-1439626454{display:block;}.isDesktop .SongListItem.disabled:hover .btnPlay.jsx-1439626454,.isDesktop .SongListItem.disabled:hover .btnPause.jsx-1439626454{display:none;}.SongListItem.disabled:hover .count.jsx-1439626454{display:block;}.count.jsx-1439626454{color:#959595;}.topItem.jsx-1439626454{font-size: 2.6875rem ;;font-weight:bold;color:#fff;}.topCount.jsx-1439626454{color:#fff;}</style><style id="__jsx-2493651356">.SongName.jsx-2493651356,.SongName.jsx-2493651356 a.jsx-2493651356{max-width:100%;
//     white-space: nowrap;
//     overflow: hidden;


.player--wrap{
    width: 100%;
    height: 0;
    background-color: #000;
    color: #fff;
    overflow: hidden;
    position: relative;
    padding-top: 56.25%;

    .player--error,
    .video-js,
    .video-player-box{
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }

    video{
        width: 100%;
    }
}
