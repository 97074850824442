// Fonts
// @import url('https://fonts.googleapis.com/css?family=Prompt');
// @import 'fonts';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// icon fontawesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';


@import '~swiper/swiper-bundle.css';
@import '~sweetalert2/src/sweetalert2.scss';
// @import '~vue-plyr/dist/vue-plyr.css';
@import 'video';
@import 'video-js';
@import 'status';

@import 'reset';
@import 'main';
@import 'responsive';
