$gradient: linear-gradient(269.16deg, #FFE580 -15.83%, #FF7571 -4.97%, #FF7270 15.69%, #EA5DAD 32.43%, #C2A0FD 50.09%, #9867F0 67.47%, #3BF0E4 84.13%, #33CE43 105.13%, #B2F4B6 123.24%);

@mixin cover-parent() {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

@media (max-width: 767.89px){

    .header-primary {

        .header-nav{
            border-bottom: 1px solid $border-color;
        }
        // .desktop--menu-toggle,
        // .header-search{
        //     display: none !important;
        // }
    }

    .card-swiper-wrap{
        overflow: hidden;
        margin-left: -15px;
        margin-right: -15px;

        .card-swiper{
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            padding-bottom: 20px;
            margin:0 0 -20px;
            padding-left: 7px;
            padding-right: 7px;

            .card-swiper-item{
                flex: 0 0 calc(89.6% + 14.336px);
                max-width: calc(89.6% + 14.336px);

                padding-left: 8px;
                padding-right: 8px;

                .post{
                    // box-shadow: 0 0 12px rgba(0,0,0,.03);
                    .post-title{
                        // font-size: 13px;
                    }

                    .post-body{
                        padding:.5rem 0 0 !important;
                    }
                }
            }
        }
    }

    .table-video{
        // display: block;

        tbody, tr, td {
            // display: block;
        }

        .td-image{
            width: 128px;
            padding-right: 8px;
        }

        .table-video-status{
            font-size: 18px;
        }

        .td-status{
            display: none;
        }

        // tr{
        //     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        //     padding-bottom: 15px;
        //     padding-top: 15px;
        // }

        tr:first-child{
            padding-top: 0;
        }
    }

    .module-header-title{
        font-size: 20px;
        font-weight: bold;
    }
    .module-header-subtitle{
        font-size: 1rem;
    }

    h2, .h2 {
        font-size: 20px;
    }

    .module-head::before{
        height: 24px;
    }

    .tags{
        .tag{
            display: inline-block;
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border-radius: 0.2rem;

            background-color: $light;

            margin: 3px 1px !important;
        }
    }
}

@media (max-width: 575.89px){
    .banner-topic{
        overflow: hidden;

        .swiper-container{
            overflow: visible;
        }
    }

    .posts-grid{
        margin-right: -7px;
        margin-left: -7px;


        .col-6{
            padding-right: 7px;
            padding-left: 7px;
        }
    }

    // .container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm{
    //     padding-right: 0;
    //     padding-left: 0;

    //     .row{
    //         margin-right: 0;
    //         margin-left: 0;

    //         .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
    //             padding-right: 0;
    //             padding-left: 0;
    //         }
    //     }
    // }
}

@media (min-width: 768px){

    body{
        // background-color: #e5e6ea;
    }

    .banner-horizontal{
        // min-height: 227px;

        .banner-container{
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }


    .featured-header{
        .featured-headline{
            font-size: 56px;
            line-height: 1.07143;
            font-weight: 700;
            letter-spacing: -.005em;

            margin-left: auto;
            margin-right: auto;
            width: 83.33333%;

            max-width: 980px;
        }

        .featured-subhead{

            font-size: 24px;
        }
    }

    .table-video{
        td{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .td-image{
            width: 240px;
        }

        .td-name{
            padding-left: 16px;
        }

        .td-status{
            width: 10px;
            white-space: nowrap;
            padding-left: 24px;
            padding-right: 24px;
        }

        .table-video-title{
            font-weight: bold;
        }
    }

    .header-primary {

        // .header-nav-wrap{
        //     padding-top: 6px;
        //     padding-bottom: 6px;
        // }

        // .header-nav{
            // background-color: $primary;
            // color: color-yiq($primary);
        // }

        .navbar-brand{
            margin-top: 6px;
            margin-bottom: 6px;
            padding: 0;
            // width: 258px;
            // height: 48px;
            // border-radius: 0.3rem;
            margin-right: 16px;
            // height: 72px;
            // width: 220px;

            height: 150px;
            width: 150px;

            // background-image: url(../images/logos/logo.png);


            >span{
                margin-left: 9px;
                display: block;
            }

            &:hover{
                // background-color: rgba(0,0,0,.05);
            }

            // background-image: url(../images/logos/logo.svg);
            // background-repeat: no-repeat;
            // margin-top: -41px;
            // box-shadow: 1px 2px 10px rgba(0,0,0,.2);

            // img{
            //     height: 40px;
            // }
        }
    }

    .banner-topic{
        .banner-topic-body{
            &:hover{
                .banner-topic-title{
                    // transform: scale(1.05);
                }
            }
        }

        .banner-topic-pic{
            img{
                // filter: grayscale(100%);
                // opacity: 0.8;
                // transition: transform 0.4s ease-in-out;
            }


        }
        .banner-topic-card{
            position: relative;
            transition: transform 0.48s cubic-bezier(.16,1,.3,1);
            transform-style: preserve-3d;

            &:hover {
                transform: scale(1.01);
                z-index: 4;

                .banner-topic-pic {
                    img{
                        filter: grayscale(0);
                        opacity: 1;
                    }
                    &:after {
                      opacity: 1;
                    }

                    &:before {
                      opacity: 0;
                    }

                    span {
                      &:after {
                        opacity: 0.9;
                      }
                    }
                  }
            }
        }

        .banner-topic-body{
            transform-style: preserve-3d;
            transition: transform 0.48s cubic-bezier(0.16, 1, 0.3, 1);
            // background: rgba(0,8,20,0.6);

        }
        .banner-topic-title{
            // transition: .3s ease-in-out;

            padding: 15px 25px;
            font-size: 3rem;

            font-weight: bold;
        }
    }

    .banner-topic-wrap{
        .swiper-container{
            overflow: visible;
        }
    }

    .post--image-move{

        .image{
            transition: all .3s;
            // position: relative;
        }

        &:hover{
            .image{
                // transform: rotate(2deg) scale(1.1);
                transform: scale(1.1);
            }
        }
    }


    .banner-topic--border-topleft{
        top: 35%;border-width: 2px 0px 0 2px;
    }
    .banner-topic--border-bottomleft{
        bottom: 35%;border-width: 0 0px 2px 2px;
    }
    .banner-topic--border-topright{
        top: 35%;border-width: 2px 2px 0 0;
    }
    .banner-topic--border-bottomright{
        bottom: 35%;border-width: 0 2px 2px 0;
    }

    // .footer-primary{
    //     .copyright{
    //         font-size: 14px;
    //         padding-bottom: 10px;
    //     }
    // }
}

@media (min-width: 992px){



    .svelte{
        display: flex;
        flex-wrap: wrap;

        .brand{
            width: 16.66666667%;
            padding-right: 15px;
        }
        .top-links{
            width: 50%;
            flex: 0 0 auto;
        }

        .social-icons{
            margin: 0 15px 0 auto;
        }
    }

    .posts-grid{

        .post{
            background-color: #fff;
            // border-radius: 12px;
            overflow: hidden;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
            margin-bottom: 30px;

            &:hover{
                .post-figure{
                    .image{
                        transform:scale(1.03)
                    }
                }

            }
        }

        .post-title{

            -webkit-line-clamp: 2;
            font-size: 20px;
            height: 48px;


        }

        .post-body{
            padding: 0.5rem 1rem ;
        }

        .post-footer,
        .post-meta{
            display: block;
        }


        .post-footer{
            padding: 0.5rem 1rem;
        }
        .post-body--category{
            padding-left: 1rem;
            padding-right: 1rem;

            font-size: 14px;
        }

    }

    .article{

        .figure-wrap{
            margin-left: 0;
            margin-right: 0;
        }
        .post-body--category{
            font-size: 14px;
        }
    }

    .banner-topic-wrap{
        // padding-top: 60px;
    }

    .home-banner-swiper {

        &.module{
            // padding-left: 30px;
            // padding-right: 30px;
            // padding-top: 30px;
            // padding-bottom: 70px;
            padding: 30px;
        }

        .swiper-slide{
            // width: 33.333333%;
            width: 25%;
        }

        .post-content{

            width: 90%;
            padding: 20px;

            left: 20px;
            bottom: 20px;


            .post-title{
                font-size: 1.8rem;
            }
        }

        .home-banner-swiper-controls{
            display: block;
        }

        .swiper-controls--next,
        .swiper-controls--prev{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);

            border-width: 0;
            background-color: #fff;
            color: #000;
            box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
        }
        .swiper-controls--prev{

            left: 20px;
            right: auto;

        }
        .swiper-controls--next{
            right: 20px;
            left: auto;
        }
    }

    .desktop-menu-dropdown{
        padding-left: 200px;
        padding-right: 200px;
    }
}

@media (min-width: 1024px){
    .login-container{
        background-color: transparent;

        .card{
            box-shadow: 0 3px 10px 0 rgba(0,0,0,.14);
        }
    }

    .home-feed .bg-footer{
        position: absolute;
        bottom: -300px;
        left: 0;
        width: 100%;
        height: 300px;
        background-color: $primary;
        transform: skew(0, -7.5deg);
        transform-origin: bottom left;
        z-index: 0;
    }
}

// .label-check{

// }




.header-primary{
    min-height: 56px;
}
@media (min-width: 768px){
    .header-primary{
        min-height: 84px;
    }

    .banner-topic-wrap{
        .swiper-slide{
            width: 33.333333%;
        }
    }
}




.home-banner-swiper{
    .swiper-slide{
        width: 80%;
        margin-right: 15px;
    }
}
@media (min-width: 480px){
    .home-banner-swiper{
        .swiper-slide{
            width: 50%;
            margin-right: 15px;
        }
    }

}
@media (min-width: 1400px){
    .home-banner-swiper{
        .swiper-slide{
            width: 33.333333%;
            margin-right: 20px;
            width: 25%;
        }
    }

    .banner-topic-wrap{
        .swiper-slide{
            width: 33.333333%;
            width: 25%;
        }
    }
}
// @media (min-width: 1600px){
//     .home-banner-swiper{
//         .swiper-slide{
//             width: 25%;
//         }
//     }
// }


@media (max-width: 767.89px){

    .footer-list{
        a{
            font-size: 14px;
            display: block;
            background-color: rgba(255,255,255,.1);
            margin-bottom: 6px;
            border-radius: 3px;
            padding: 8px 12px;
            text-decoration: none;
        }
    }
}
