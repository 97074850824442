
html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
button, form, input, select, textarea {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: 0;
}

body{
    overflow-y: scroll;
}

table{
    width: 100%;
}
// .container-fluid-2200 {
//     margin-right: auto;
//     margin-left: auto;
//     padding-left: 10px;
//     padding-right: 10px;
//     padding-left: 50px;
//     padding-right: 50px;
//     max-width: 2300px;
// }
// .container-fluid-1200 {
//     margin-right: auto;
//     margin-left: auto;
//     padding-left: 10px;
//     padding-right: 10px;
//     max-width: 1280px;
//     padding-left: 40px;
//     padding-right: 40px;
// }

.bg-gray{
    background-color: #e5e6ea;
}

@media (min-width: 576px) {
    .container-sm,
    .container {
        max-width: 100%;
    }

}
// @media (min-width: 768px) {
//     .container-md,
//     .container-sm,
//     .container {
//         max-width: 100%;
//         // padding-left: 0;
//         // padding-right: 0;
//     }
// }
// @media (min-width: 992px) {
//     .container-lg,
//     .container-md,
//     .container-sm,
//     .container {
//         max-width: 100%;
//         // padding-left: 0;
//         // padding-right: 0;
//     }
// }
// @media (min-width: 1200px) {
//     .container-xl,
//     .container-lg,
//     .container-md,
//     .container-sm,
//     .container {
//         max-width: 100%;
//         // padding-left: 0;
//         // padding-right: 0;
//     }
// }

// @media (min-width: 1400px) {
//     .container-xl,
//     .container-lg,
//     .container-md,
//     .container-sm,
//     .container {
//         max-width: 1340px;
//     }
// }

@media (min-width: 1600px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1600px;
    }
}

ul{
    padding: 0;
}
ol, ul {
    list-style: none;
}
button{
    font-size: inherit;
    line-height: inherit;
    border: none;
    cursor: pointer;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    padding: 0;

    &:focus{
        outline: none;
    }
}

.fs-12{
    font-size: 12px;
}
.fs-13{
    font-size: 13px;
}
.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-20{
    font-size: 20px;
}
.fs-24{
    font-size: 24px;
}


.min-w-100{
    min-width: 100px;
}
.w-min-120,
.min-w-120{
    min-width: 120px;
}
.min-w-160{
    min-width: 160px;
}
.min-w-180{
    min-width: 180px;
}
.min-w-200{
    min-width: 200px;
}
// @media (min-width: 1600px) {
//     .container-xl,
//     .container-lg,
//     .container-md,
//     .container-sm,
//     .container {
//         max-width: 1540px;
//     }
// }

// @media (min-width: 1900px) {
//     .container-xl,
//     .container-lg,
//     .container-md,
//     .container-sm,
//     .container {
//         max-width: 1840px;
//     }
// }

svg{
    fill: currentColor
}

.btn-white{

    color: color-yiq($white);
    background-color: $white;
    border-color: $white;

    &:hover,
    &.hover{
        color: color-yiq($white);
        background-color: darken($white, 7.5%);
        border-color: darken($white, 10%);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($white, .5);
    }

    &:active,
    &.active{
        color: color-yiq($white);
        background-color: darken($white, 10%);
        border-color: darken($white, 12.5%);
    }
}

.btn-orange{

    color: color-yiq($orange);
    background-color: $orange;
    border-color: $orange;

    &:hover,
    &.hover{
        color: color-yiq($orange);
        background-color: darken($orange, 7.5%);
        border-color: darken($orange, 10%);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($orange, .5);
    }

    &:active,
    &.active{
        color: color-yiq($orange);
        background-color: darken($orange, 10%);
        border-color: darken($orange, 12.5%);
    }
}
.btn-gold{

    color: color-yiq($gold);
    background-color: $gold;
    border-color: $gold;

    &:hover,
    &.hover{
        color: color-yiq($gold);
        background-color: darken($gold, 7.5%);
        border-color: darken($gold, 10%);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($gold, .5);
    }

    &:active,
    &.active{
        color: color-yiq($gold);
        background-color: darken($gold, 10%);
        border-color: darken($gold, 12.5%);
    }
}


.swal2-styled.swal2-cancel,
.swal2-styled.swal2-confirm{
    min-width: 120px ;
}

.swal2-styled.swal2-confirm{
    color: color-yiq($primary) !important;
    background-color: $primary!important;
    border-color: $primary!important;

    &:hover,
    &.hover{
        color: color-yiq($primary) !important;
        background-color: darken($primary, 7.5%) !important;
        border-color: darken($primary, 10%) !important;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5) !important;
    }

    &:active,
    &.active{
        color: color-yiq($primary) !important;
        background-color: darken($primary, 10%) !important;
        border-color: darken($primary, 12.5%) !important;
    }
}

.swal2-styled.swal2-cancel{
    color: color-yiq($light) !important;
    background-color: $light !important;
    border-color: $light !important;

    &:hover,
    &.hover{
        color: color-yiq($light) !important ;
        background-color: darken($light, 7.5%) !important   ;
        border-color: darken($light, 10%) !important    ;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($light, .5) !important  ;
    }

    &:active,
    &.active{
        color: color-yiq($light) !important ;
        background-color: darken($light, 10%) !important    ;
        border-color: darken($light, 12.5%) !important  ;
    }
}

/**/
/* bread crumps */
/**/
.bread-crumps {
	// padding-top: 1px;
	// border-bottom: 1px dashed #bfbfbf;
    // color: #808080;
    color: #fff;

    a{
        color: #fff;
    }
}
.bread-crumps li {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	// font-size: 13px;
	// line-height: 34px;
}
.bread-crumps li:before {
	content: '//';
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	text-align: center;
}
.bread-crumps li:first-child {
	padding-left: 0;
}
.bread-crumps li:first-child:before {
	content: '';
}



.card-table {
    margin-bottom: 0;

    th,
    td {
        border-bottom: 1px solid #f0f4f7;
        padding: 8px 18px;
    }

    thead th {
        background: rgba(240, 244, 247, 0.5);
        color: #737376;
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: normal;
        font-size: 14px;
        white-space: nowrap;
    }

    .td-status {
        text-align: center;
        width: 80px;
    }
    .td-email {
        width: 170px;

        > div {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 170px;
        }
    }

    .td-checkbox {
        text-align: center;
        width: 15px;
        padding-right: 0;
    }

    .td-qty {
        text-align: center;
        width: 50px;
    }

    .td-drag,
    .td-index {
        text-align: center;
        width: 10px;
        padding-right: 0;
    }

    .td-drag {
        opacity: 0.5;
        cursor: move;
    }

    .td-date {
        width: 60px;
    }
    .td-progress {
        width: 180px;
    }
}

.text-content,
.text-editor{
    img{
        width: 100%;
        height: auto;
    }

    ol{
        list-style-type: decimal;
    }

    table{
        width: 100%;
    }
    table th,
    table td{
        padding: 3px 10px;
        border: 1px solid #ccc;
    }

    table th{
        background-color:#efefef;
        font-weight: bold;
        color:#174487;
    }

}

.elem-loading{
    display: flex;

}
.elem-loading,
.btn-loading{
    position: relative;

    .loader {
        position: absolute;
        height: auto;
        width: 100%;
        z-index: 2;
        left: 0;
        top: 50%;
        text-align: center;
        pointer-events: none;
        // opacity: 0;
        transform: translateY(-50%);

       > span {
            display: inline-block;
            float: none;
            vertical-align: baseline;
            width: 8px;
            height: 8px;
            padding: 0;
            border: none;
            margin: 2px;
            opacity: .4;
            border-radius: 7px;
            background-color: rgba(255, 255, 255, 0.9);
            transition: background-color .2s;
            animation: btn-loading-animate 1s infinite;
        }
        > span:nth-child(3n+2) {
            animation-delay: .15s;
        }
        > span:nth-child(3n+3) {
            animation-delay: .3s;
        }
    }

    .btn-text{
        opacity: 0;
    }
}

@keyframes btn-loading-animate {
    0% {
      opacity: .4;
    }

    20% {
      opacity: .4;
    }

    50% {
      opacity: 1;
    }

    to {
      opacity: .4;
    }
  }

  .backdrop-modal{
    background: rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    font-size: 18px;

    .loader > span {
        width: 12px;
        height: 12px;

        border-radius: 50%;
        margin: 5px;
    }
  }


.btn-action{
    width: 38px;
    height: 38px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;

    &.btn-lg{
        width: 42px;
        height: 42px;
    }
}

.header-topbar--hover{
    position: relative;

    margin-top: -8px;
    margin-bottom: -8px;
    padding-top: 8px;
    padding-bottom: 8px;

    .header-topbar-link{
        cursor: pointer;


    }

    // &:hover{

    //     .header-topbar--hover-dropdown{
    //         display: block;
    //     }
    // }
}

.header-topbar--hover-dropdown{
    position: absolute;
    top: 100%;
    // left: 0;
    right: 0;
    background-color: #fff;


    box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.12), 0 -1px 2px 0 rgba(0, 0, 0, 0.08);
    border-width: 0;
    padding: 0.5rem;

}
